// dataFunctions.js
// Date Imports
import { parseISO, format, startOfWeek, endOfWeek } from 'date-fns';

// filterColumns
export const filterColumns = (data, columnsToKeep) => {
  if (!Array.isArray(data) || !Array.isArray(columnsToKeep)) {
    throw new Error("Invalid arguments: data and columnsToKeep should be arrays");
  }

  return data.map(item => {
    return columnsToKeep.reduce((acc, column) => {
      if (item[column] !== undefined) {
        acc[column] = item[column];
      }
      return acc;
    }, {});
  });
};

// sumColumns
export const sumColumns = (data, columnsToSum, newColumnName) => {
  if (!Array.isArray(data) || !Array.isArray(columnsToSum) || typeof newColumnName !== 'string') {
    throw new Error("Invalid arguments: data and columnsToSum should be arrays, and newColumnName should be a string");
  }

  return data.map(item => {
    const sum = columnsToSum.reduce((acc, column) => {
      if (typeof item[column] === 'number') {
        return acc + item[column];
      }
      return acc;
    }, 0);

    return { ...item, [newColumnName]: sum };
  });
};

// transformDataToJSON
export const transformDataToJSON = (data, columnsToKeep) => {
  if (!Array.isArray(data)) {
    throw new Error("Invalid argument: data should be an array");
  }

  if (!Array.isArray(columnsToKeep) || columnsToKeep.length < 3) {
    throw new Error("Invalid argument: columnsToKeep should be an array with at least three elements");
  }

  const [periodColumn, dateColumn, dataColumn] = columnsToKeep;

  const groupedData = data.reduce((acc, item) => {
    const periodValue = item[periodColumn];
    const dateValue = item[dateColumn];
    const dataValue = item[dataColumn];

    if (!acc[periodValue]) {
      acc[periodValue] = {
        name: periodValue,
        data: [],
        date: [],
      };
    }
    acc[periodValue].data.push(dataValue);
    acc[periodValue].date.push(dateValue);
    return acc;
  }, {});

  const result = Object.values(groupedData);
  return result;
};
  
// dateDifference
export const dateDifference = (selectedDateRange) => {
    const [currentStart, currentEnd] = selectedDateRange;
        const rangeDays = Math.ceil((currentEnd - currentStart) / (1000 * 60 * 60 * 24));
        const previousStart = new Date(currentStart);
        previousStart.setDate(previousStart.getDate() - rangeDays);
        const previousEnd = new Date(currentEnd);
        previousEnd.setDate(previousEnd.getDate() - rangeDays);
    return {previousStart, previousEnd, rangeDays};
};

// useAggregatedData
export const useAggregatedData = (data, variable_groupby, variable_aggregation, calculation_type) => {
  if (!Array.isArray(data)) {
    throw new Error("Invalid argument: data should be an array");
  }

  const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
  
  const groupedData = data.reduce((acc, item) => {
    let groupValue;
    if (variable_groupby === 'day_of_week') {
      const date = parseISO(item.date);
      groupValue = format(date, 'EEEE');
    } else {
      groupValue = item[variable_groupby];
    }
    
    if (!acc[groupValue]) {
      acc[groupValue] = [];
    }
    acc[groupValue].push(item[variable_aggregation]);
    return acc;
  }, {});

  const aggregatedData = Object.keys(groupedData).map(key => {
    const values = groupedData[key];
    let aggregateValue;
    if (calculation_type === 'sum') {
      aggregateValue = values.reduce((sum, value) => sum + value, 0);
    } else if (calculation_type === 'count') {
      aggregateValue = values.length;
    } else if (calculation_type === 'countd') {
      aggregateValue = new Set(values).size;
    } else if (calculation_type === 'average') {
      aggregateValue = values.reduce((sum, value) => sum + value, 0) / values.length;
    } else {
      aggregateValue = values.length;
    }

    return { day: key, value: aggregateValue };
  });

  const sortedAggregatedData = variable_groupby === 'day_of_week'
    ? aggregatedData.sort((a, b) => {
        return daysOfWeek.indexOf(a.day) - daysOfWeek.indexOf(b.day);
      })
    : aggregatedData;

  const result = {
    name: 'Orders',
    data: sortedAggregatedData.map(item => item.value),
  };

  return [result];
};

// aggregateDataByDate
export const aggregateDataByDate = (data, dateGroupBy, variables_aggregation, calculation_type) => {
  if (!Array.isArray(data)) {
    throw new Error("Invalid argument: data should be an array");
  }

  if (!Array.isArray(variables_aggregation)) {
    throw new Error("Invalid argument: variables_aggregation should be an array");
  }

  let groupedData = {};

  data.forEach(item => {
    const date = parseISO(item.date);
    let groupValue;
    switch (dateGroupBy) {
      case 'day_of_week':
        groupValue = format(date, 'EEEE'); // e.g., "Monday"
        break;
      case 'day':
        groupValue = format(date, 'EEEE, MMMM d'); // e.g., "Monday, June 17"
        break;
      case 'week':
        const weekStart = startOfWeek(date);
        const weekEnd = endOfWeek(date);
        groupValue = `${format(weekStart, 'EEEE, MMMM d')} - ${format(weekEnd, 'EEEE, MMMM d')}`; // e.g., "Monday, June 17 - Sunday, June 23"
        break;
      case 'month':
        groupValue = format(date, 'MMMM, yyyy'); // e.g., "June, 2023"
        break;
      default:
        throw new Error("Invalid date group by value");
    }

    if (!groupedData[groupValue]) {
      groupedData[groupValue] = {};
      variables_aggregation.forEach(variable => {
        groupedData[groupValue][variable] = [];
      });
    }

    variables_aggregation.forEach(variable => {
      groupedData[groupValue][variable].push(item[variable]);
    });
  });

  const aggregatedData = Object.keys(groupedData).map(key => {
    const values = groupedData[key];
    let result = { date: key };

    Object.keys(values).forEach(variable => {
      const variableValues = values[variable];
      let aggregateValue;
      if (calculation_type === 'sum') {
        aggregateValue = variableValues.reduce((sum, value) => sum + value, 0);
      } else if (calculation_type === 'count') {
        aggregateValue = variableValues.length;
      } else if (calculation_type === 'countd') {
        aggregateValue = new Set(variableValues).size;
      } else if (calculation_type === 'average') {
        aggregateValue = variableValues.reduce((sum, value) => sum + value, 0) / variableValues.length;
      } else {
        aggregateValue = variableValues.length;
      }

      result[variable] = aggregateValue;
    });

    return result;
  });

  return aggregatedData;
};

// aggregateDataByDayAndHour
export const aggregateDataByDayAndHour = (data, date_variable, variables_aggregation, calculation_type) => {
  if (!Array.isArray(data)) {
    throw new Error("Invalid argument: data should be an array");
  }

  if (!Array.isArray(variables_aggregation)) {
    throw new Error("Invalid argument: variables_aggregation should be an array");
  }

  let groupedData = {};

  data.forEach(item => {
    const date = parseISO(item[date_variable]);
    const dayOfWeek = format(date, 'EEEE'); // e.g., "Monday"
    const hour = format(date, 'HH'); // e.g., "14" for 2 PM
    const groupKey = `${dayOfWeek}-${hour}`; // Unique key for grouping

    if (!groupedData[groupKey]) {
      groupedData[groupKey] = {
        date: dayOfWeek,
        hour: hour
      };
      variables_aggregation.forEach(variable => {
        groupedData[groupKey][variable] = [];
      });
    }

    variables_aggregation.forEach(variable => {
      groupedData[groupKey][variable].push(item[variable]);
    });
  });

  const aggregatedData = Object.keys(groupedData).map(key => {
    const values = groupedData[key];
    let result = {
      date: values.date,
      hour: values.hour
    };

    Object.keys(values).forEach(variable => {
      if (variable !== 'date' && variable !== 'hour') {
        const variableValues = values[variable];
        let aggregateValue;
        if (calculation_type === 'sum') {
          aggregateValue = variableValues.reduce((sum, value) => sum + value, 0);
        } else if (calculation_type === 'count') {
          aggregateValue = variableValues.length;
        } else if (calculation_type === 'countd') {
          aggregateValue = new Set(variableValues).size;
        } else if (calculation_type === 'average') {
          aggregateValue = variableValues.reduce((sum, value) => sum + value, 0) / variableValues.length;
        } else {
          aggregateValue = variableValues.length;
        }

        result[variable] = aggregateValue;
      }
    });

    return result;
  });

  return aggregatedData;
};

// restructureDataByDayAndHour
const restructureDataByDayAndHour = (aggregatedData, variables_aggregation) => {
  const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
  let resultStructure = daysOfWeek.map(day => ({
    name: day,
    data: Array(24).fill(0)
  }));

  // Populate the result structure with aggregated data
  aggregatedData.forEach(item => {
    let day = resultStructure.find(day => day.name === item.date);
    if (day) {
      day.data[parseInt(item.hour, 10)] = item[variables_aggregation[0]] || 0;
    }
  });

  return resultStructure;
};

// aggregateDataByColumn
export const aggregateDataByColumn = (data, columnGroupBy, variable_aggregation, calculation_type) => {
  if (!Array.isArray(data)) {
    throw new Error("Invalid argument: data should be an array");
  }

  const groupedData = data.reduce((acc, item) => {
    let groupValue = item[columnGroupBy];

    if (!acc[groupValue]) {
      acc[groupValue] = [];
    }
    acc[groupValue].push(item[variable_aggregation]);
    return acc;
  }, {});

  const aggregatedData = Object.keys(groupedData).map(key => {
    const values = groupedData[key];
    let aggregateValue;
    if (calculation_type === 'sum') {
      aggregateValue = values.reduce((sum, value) => sum + value, 0);
    } else if (calculation_type === 'count') {
      aggregateValue = values.length;
    } else if (calculation_type === 'countd') {
      aggregateValue = new Set(values).size;
    } else if (calculation_type === 'average') {
      aggregateValue = values.reduce((sum, value) => sum + value, 0) / values.length;
    } else {
      aggregateValue = values.length;
    }

    return { column: key, value: aggregateValue };
  });

  return {
    name: variable_aggregation,
    data: aggregatedData.map(item => item.value),
  };
};

// aggregateDataByColumnV2
export const aggregateDataByColumnV2 = (data, groupBy, variables_aggregation, calculation_type) => {
  if (!Array.isArray(data)) {
    throw new Error("Invalid argument: data should be an array");
  }

  if (!Array.isArray(variables_aggregation)) {
    throw new Error("Invalid argument: variables_aggregation should be an array");
  }

  let groupedData = {};

  data.forEach(item => {
    const groupValue = item[groupBy]; // Group by the specified column

    if (!groupedData[groupValue]) {
      groupedData[groupValue] = {};
      variables_aggregation.forEach(variable => {
        groupedData[groupValue][variable] = [];
      });
    }

    variables_aggregation.forEach(variable => {
      groupedData[groupValue][variable].push(item[variable]);
    });
  });

  const aggregatedData = Object.keys(groupedData).map(key => {
    const values = groupedData[key];
    let result = { [groupBy]: key };

    Object.keys(values).forEach(variable => {
      const variableValues = values[variable];
      let aggregateValue;
      if (calculation_type === 'sum') {
        aggregateValue = variableValues.reduce((sum, value) => sum + value, 0);
      } else if (calculation_type === 'count') {
        aggregateValue = variableValues.length;
      } else if (calculation_type === 'countd') {
        aggregateValue = new Set(variableValues).size;
      } else if (calculation_type === 'average') {
        aggregateValue = variableValues.reduce((sum, value) => sum + value, 0) / variableValues.length;
      } else {
        aggregateValue = variableValues.length;
      }

      result[variable] = aggregateValue;
    });

    return result;
  });

  return aggregatedData;
};

// serializeDataAxis
export const serializeDataAxis = (dataArray) => {
  const transformedData = dataArray.map(item => ({
    x: item.date,
    y: item.data
  }));
  
  return {
    data: transformedData
  };
};

// extractAndFormatDataStackedChart
// export const extractAndFormatDataStackedChart = (data, order, name) => {
//   if (!Array.isArray(data) || data.length === 0) {
//     console.error("Invalid argument: data should be a non-empty array");
//     return [{ name: name, data: order.map(() => 0) }];
//   }

//   const formattedData = {
//     name: name,
//     data: order.map(key => data[0][key] ?? 0)
//   };

//   return [formattedData];
// };

export const extractAndFormatDataStackedChart = (data, order, labels) => {
  if (!Array.isArray(data) || data.length === 0) {
    console.error("Invalid argument: data should be a non-empty array");
    return order.map((key, index) => {
      return {
        label: labels[index],
        y: 0
      };
    });
  }
  return order.map((key, index) => {
    return {
      label: labels[index],
      y: data[0][key]
    };
  });
};

export const transformDataTreeMap = (dataArray) => {
  // Initialize a map to store data by day of the week
  const dayOfWeekMap = {};

  dataArray.forEach(entry => {
    // Ensure entry.day_of_week is not null or undefined
    if (entry.day_of_week && entry.hour != null && entry.total_orders != null) {
      const dayOfWeek = entry.day_of_week.trim();
      const hour = entry.hour;
      const totalOrders = entry.total_orders;

      if (!dayOfWeekMap[dayOfWeek]) {
        dayOfWeekMap[dayOfWeek] = new Array(24).fill(0);
      }

      dayOfWeekMap[dayOfWeek][hour] += totalOrders;
    }
  });

  // Convert the map to the desired array format
  const result = Object.keys(dayOfWeekMap).map(day => ({
    name: day,
    data: dayOfWeekMap[day]
  }));

  return result;
};
