// routes.js
// React Imports
import React from 'react';
// Pages
import Overview from 'views/dashboard/overview/overview.jsx';
import MarketingInsights from 'views/dashboard/marketing/insights.jsx';
import MarketingMeta from 'views/dashboard/marketing/meta.jsx';
import MarketingGoogle from 'views/dashboard/marketing/google.jsx';
import TicketsInsights from 'views/dashboard/tickets/insights.jsx';
import TicketsAttendance from 'views/dashboard/tickets/attendance.jsx';
// import TicketsMap from 'views/dashboard/tickets/map.jsx';
// Icons
import {
  OverviewIcon,
  MarketingIcon,
  MarketingInsightsIcon,
  MarketingMetaIcon,
  MarketingGoogleIcon,
  TicketsIcon,
  TicketsAttendanceIcon,
  // TicketsMapIcon,
} from "components/icons/Icons";

const routes = [
  {
    name: 'Overview',
    layout: '/dashboard',
    path: '/overview',
    icon: <OverviewIcon color="inherit" />,
    component: <Overview />,
  },
  {
    name: 'Marketing',
    path: '/marketing',
    icon: <MarketingIcon color="inherit" />,
    collapse: true,
    items: [
      {
        name: 'Insights',
        layout: '/dashboard',
        path: '/marketing/insights',
        component: <MarketingInsights />,
        icon: <MarketingInsightsIcon color="inherit" />,
      },
      {
        name: 'Meta',
        layout: '/dashboard',
        path: '/marketing/meta',
        component: <MarketingMeta />,
        icon: <MarketingMetaIcon color="inherit" />,
      },
      {
        name: 'Google',
        layout: '/dashboard',
        path: '/marketing/google',
        component: <MarketingGoogle />,
        icon: <MarketingGoogleIcon color="inherit" />,
      },
    ],
  },
  {
    name: 'Tickets',
    path: '/tickets',
    icon: <TicketsIcon color="inherit" />,
    collapse: true,
    items: [
      {
        name: 'Insights',
        layout: '/dashboard',
        path: '/tickets/insights',
        component: <TicketsInsights />,
        icon: <MarketingInsightsIcon color="inherit" />,
      },
      {
        name: 'Attendance',
        layout: '/dashboard',
        path: '/tickets/attendance',
        component: <TicketsAttendance />,
        icon: <TicketsAttendanceIcon color="inherit" />,
      },
      // {
      //   name: 'Map',
      //   layout: '/dashboard',
      //   path: '/tickets/map',
      //   component: <TicketsMap />,
      //   icon: <TicketsMapIcon color="inherit" />,
      // },
    ],
  },
];

export default routes;
